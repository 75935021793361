/** @jsx */
import { css } from '@emotion/react'
import { Component } from 'react';
import React from 'react'
// import PropTypes from 'prop-types'
import { 
    Container,
    Typography
} from '@material-ui/core';
import EvaluacionCard from '../EvaluacionCard'

const textHeader = css`
    text-align: center
  `

class Evaluacion extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <Container>  
                <Typography align="center" color='secondary' font-size="3rem" fontWeight='fontWeightBold'>
                    <h2 css={textHeader}>Tenemos opciones para ti</h2>
                </Typography>
                <EvaluacionCard pageContext={this.props.pageContext}/>
            </Container>
        )
    }
}

Evaluacion.propTypes = {

};

export default Evaluacion;


