/** @jsx */
import { css } from '@emotion/react'
import React, { Component } from 'react'
import {
    CardElevation,
    ButtonDegrade
} from '@findep/mf-landings-core';
import { 
    Typography
} from '@material-ui/core';
// import {
//     Divider
// } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Arcoiris from './elements/Arcoiris';
import { navigate } from "../helpers/queryNavigate"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import formatNumber  from '../helpers/formatNumber'

const textCard = css`
    justify-content: flex-end;
  `
export default class EvaluacionCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bcScore: !((typeof window !== 'undefined') && sessionStorage.getItem('bcScore') === null) ? (typeof window !== 'undefined') ? JSON.parse(sessionStorage.getItem('bcScore')) : '' : '',
            color: !((typeof window !== 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window !== 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')).datosAdicionales.color : '' : '',
            _disabled: {},
            mejorarhistorial: '',
            buttonevaluacion: false,
            btnActive: true,
            monto: !((typeof window !== 'undefined') && sessionStorage.getItem('cotizador') === null) ? (typeof window !== 'undefined') ? JSON.parse(sessionStorage.getItem('cotizador')).monto : '' : '',
        }
        // console.log(this.state.flujo)
    }

    handleChange(prop, data) {
        const state = {
            [prop]: data.value
        }
        this.setState(state)
        console.log(prop + data.value)
        if (prop === 'mejorarhistorial') {
            this.setState({btnActive: false})
        } 
    }

    // async setDisabled(obj) {
    //     let {_disabled} = this.state
    //     for (var property in obj) {
    //         if (obj.hasOwnProperty(property)) { // Do things here
    //             _disabled[property] = true
    //         }
    //     }
    //     await this.setState({_disabled})
    // }

    async nextPage() {
        const {mejorarhistorial} = this.state
        if (mejorarhistorial === true) {
            navigate(this.props.pageContext?.next?.path)
        } else {
            navigate(this.props.pageContext?.errorPath)
        }
    }

    render() {
        return (
            <div>
                <CardElevation>
                  <Grid item style={{align: "center"}}>
                    <Arcoiris color={this.state.color} score={this.state.bcScore}/>
                  </Grid>
                    <br/>
                    <Typography>
                        No alcanzas {formatNumber(this.state.monto, 3, ',', '$', 0, '')}, pero la buena noticia es que sí te podemos prestar <strong>$5,000</strong> ¡para que te los lleves ya!
                    </Typography>
                </CardElevation>
                <br />
                <CardElevation>
                    <FormControl>
                        <RadioGroup value={this.state.mejorarhistorial}>
                            <FormControlLabel 
                                value={true} 
                                control={<Radio id="evaluacion-mejorar-historial-si" name="evaluacion" />} 
                                label="¡Sí, quiero $5,000*!"
                                // checked={
                                //     this.state.mejorarhistorial === true
                                // }
                                onChange={
                                    () => this.handleChange('mejorarhistorial', {value: true})
                                }
                            />
                            <p css={textCard}>
                                *Hasta $5,000 de crédito a tan solo unos clicks de distancia, sujeto a aprobación. Al continuar te solicitamos algunos datos adicionales.
                            </p>
                            <FormControlLabel 
                                value={false}
                                control={<Radio id="evaluacion-mejorar-historial-no" name="evaluacion" />} 
                                label="No quiero $5,000 gracias" 
                                // checked={
                                //     this.state.nomejorarhistorial === true
                                // }
                                onChange={
                                    () => this.handleChange('mejorarhistorial', {value: false})
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </CardElevation>
                <br/>
                <Grid container justify="center">
                    <ButtonDegrade 
                        id="evaluacion-obtener-prestamo-button"
                        textButton="OBTENER MI CRÉDITO"
                        icon={ArrowForwardIcon}
                        disabled={
                            this.state.btnActive
                        }
                        onClick={
                            () => {
                                this.nextPage()
                            }
                        }
                    />
                </Grid>
                <br />
            </div>
            
        )
    }
}
